import React from "react"
import { Layout } from "@components/layout"
import {Search} from "@components/standard"
import SingleCard from "./singleCard"

import {
  container,
  container__categories,
} from "./styles/blog.module.scss"

const Blog = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  console.log(categories)
  const notEmptyCategories = categories.filter(
    (el) => el.posts.nodes.length > 0
  )
  return (
    <Layout {...page}>
      <section className={container}>
        <h1>Blog</h1>
        {/* <p>
          Przeczytaj o Akademii, mentorach, naszych metodach nauczania i kilku
          ciekawych technologicznych rzeczach.{" "}
        </p>
        <Search />
        <div className={container__categories}>
          {notEmptyCategories.map((category, i) => {
            return <SingleCard {...category} />
          })}
        </div> */}
      </section>
    </Layout>
  )
}
export default Blog
